<template>
  <div class="ali">
    <van-nav-bar title="支付宝" fixed placeholder z-index="999" style="width: 100%">
      <template #left>
        <!-- <van-icon name="arrow-left" color="#000" @click="onClickLeft" /> -->
        <img src="../../../assets/other/back.png" style="width:12px;height:24px" @click="onClickLeft" alt="">
      </template>
    </van-nav-bar>
    <div class="box" v-html="html"></div>
  </div>
</template>

<script>
import { OrderAliPay_Api } from "@/api/order";
import { Icon, NavBar } from "vant";
export default {
  name: "orderAlipay",
  components: {
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
  },

  props: {
    orderId: [Number, String],
  },
  data() {
    return {
      html: "",
    };
  },
  mounted() {
    this.aliPay(this.orderId);
  },
  methods: {
    aliPay(orderId) {
      this.$reqPost(OrderAliPay_Api + orderId, {
        pageUrl: "/register/apply/paySuccess/",
      }).then((res) => {
        this.html = res.data;
        this.$nextTick(() => {
          document.forms[0].submit();
        });
      });
    },

    onClickLeft() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="less">
.ali {
  width: 100%;
  height: 100vh;
  .box {
    width: 100%;
    height: vh;
  }
}
</style>
